<template>
    <div class="mt-5">

        <el-row type="flex" justify="space-between">

            <el-col :span="6">
                <el-input v-model="search" placeholder="Buscar Usuario" clearable suffix-icon="el-icon-search"/>
            </el-col>

        </el-row>

        <el-row class="mt-4 px-5 py-2 bg-white border border-gray rounded">
            <el-col>
                <el-table
                    class="table m-0"
                    ref="clientTable"
                    :row-class-name="'cursor-pointer'"
                    emptyText="No se han encontrado usuarios"
                    :data="clients.filter(data => !search
                      || (data.name && data.name.toLowerCase().includes(search.toLowerCase()))
                      || data.email.toLowerCase().includes(search.toLowerCase())
                      || (data.phone && data.phone.includes(search)))">

                  <el-table-column label="Nombre">
                    <template slot-scope="data">
                      <span class="table-cell-name">{{data.row.name}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column label="Email">
                      <template slot-scope="data">
                          <span class="table-cell-name">{{data.row.email}}</span>
                      </template>
                  </el-table-column>

                  <el-table-column label="Teléfono">
                    <template slot-scope="data">
                      <span class="table-cell-name">{{data.row.phone}}</span>
                    </template>
                  </el-table-column>

                </el-table>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "ClientList",
        data() {
            return {
                search: "",
            }
        },
        created() {
            this.loadClients();
        },
        methods: {
          loadClients() {
                this.$store.dispatch('loadClients');
            },
        },
        computed: {
            ...mapGetters([
                'clients'
            ]),
        }
    }
</script>
